import React from 'react';
import _ from 'lodash';
import slugify from 'slugify';

import { createLocationSlug } from '@utils/locationHelpers';

// import settings from '../../../config/settings';
import {
  doesModuleExist as doesModuleExistFunc,
  moduleSettings as moduleSettingsFunc,
} from './nodeHelpers';

export const doesModuleExist = doesModuleExistFunc;
export const moduleSettings = moduleSettingsFunc;

export const isBlockActive = (block) => {
  const globalSettingsExists = !!(
    // if block exists.  This state should never exist
    (
      block &&
      // if block globalBlockSettings exists.  This state will exist if any internal settings are not null
      block.globalBlockSettings &&
      // if isActive is false.
      block.globalBlockSettings.isActive === false
    )
  );

  if (globalSettingsExists) {
    return block.globalBlockSettings.isActive;
  }
  return true;
};

export const lineBreakToBr = (text) => {
  const textWithBrs = [];
  text.split('\n').map((item, key) => {
    textWithBrs.push(
      <span key={key}>
        {item}
        <br />
      </span>
    );
    return null;
  });

  return textWithBrs;
};

// Resolve paths to pages... This will inevitable change... but not yet... not now
// export const LinkPathResolver = slugObject => {
//   if (slugObject && slugObject.__typename) {
//     if (
//       slugObject.__typename === 'SanityLocation' &&
//       slugObject.name &&
//       slugObject.slug &&
//       slugObject.slug.current &&
//       slugObject.group &&
//       slugObject.group.slug &&
//       slugObject.group.slug.current
//     ) {
//       return {
//         name: slugObject.name,
//         slug: `${slugObject.group.slug.current}/${slugObject.slug.current}`,
//       };
//     }
//   } else if (
//     slugObject &&
//     slugObject.pageInfo &&
//     slugObject.pageInfo.slug &&
//     slugObject.pageInfo.slug.current &&
//     slugObject.pageInfo.name
//   ) {
//     return {
//       name: slugObject.pageInfo.name,
//       slug: slugObject.pageInfo.slug.current,
//     };
//   }

//   return null;
// };

const returnLinkAttributes = ({
  anchor,
  text,
  href,
  title,
  key,
  type,
  openNew,
  slugType,
  productInfo,
}) => ({
  // This function acts as a middleware to groom and normalize the data if need be
  anchor: `${anchor && `#${anchor}`}`,
  text,
  href,
  title,
  key: key || Math.floor(Math.random() * 1000000) + 1, // if no ket is set, return random value
  type,
  openNew: openNew || null,
  slugType,
  productInfo,
});

export const linkResolver = (linkObjectArg) => {
  // if link
  if (linkObjectArg && linkObjectArg.link && linkObjectArg.link[0]) {
    const linkObject = linkObjectArg.link[0];

    // if link has text and link obj and link obj has type and that a slug exists
    if (linkObject && linkObject._type) {
      const linkText = linkObjectArg.text || '';
      const anchorText =
        (linkObjectArg.anchor && linkObjectArg.anchor.current) || '';

      const linkType = linkObject._type;

      // if link is internal
      if (
        linkType === 'linkInternal' &&
        linkObject.slug &&
        linkObject.slug.pageInfo &&
        linkObject.slug.pageInfo.slug &&
        linkObject.slug.pageInfo.slug.current
      ) {
        let customSlug = null;
        // special routes here
        if (linkObject.slug._type === 'location') {
          customSlug = createLocationSlug(linkObject.slug);
        }

        // return
        return returnLinkAttributes({
          anchor: anchorText,
          text: linkText,
          href: customSlug || linkObject.slug.pageInfo.slug.current,
          title: linkObjectArg.title || linkObject.slug.pageInfo.name || '',
          key: linkObject.id && linkObject.id,
          type: 'internal',
          slugType: linkObject.slug._type,
          productInfo: {
            price:
              linkObject.slug._type === 'product' &&
              linkObject.slug.price &&
              linkObject.slug.price,
            sku:
              linkObject.slug._type === 'product' &&
              linkObject.slug.sku &&
              linkObject.slug.sku,
            name:
              linkObject.slug._type === 'product' &&
              linkObject.slug.pageInfo.name &&
              linkObject.slug.pageInfo.name,
            slug:
              linkObject.slug._type === 'product' &&
              linkObject.slug.pageInfo.slug.current &&
              linkObject.slug.pageInfo.slug.current,
            description:
              linkObject.slug._type === 'product' &&
              linkObject.slug.descriptionCart &&
              linkObject.slug.descriptionCart,
            image:
              linkObject.slug._type === 'product' &&
              linkObject.slug.image.asset.url &&
              linkObject.slug.image.asset.url,
          },
        });
      }

      // if link is external
      if (linkType === 'linkExternal' && linkObject.url) {
        return returnLinkAttributes({
          anchor: anchorText,
          text: linkText,
          href: linkObject.url,
          title: linkObjectArg.title || linkText || '',
          key: linkObject._key && linkObject._key,
          type: 'external',
          openNew: linkObject.openNew && linkObject.openNew,
          slugType: 'external',
        });
      }
    }
  }

  return null;
};

// Returns link type
export const returnLinkType = (linkObject) =>
  linkObject && linkObject && linkObject._type ? linkObject._type : null;

// If link is internal, return true
export const isInternal = (linkObject) =>
  returnLinkType(linkObject) === 'linkInternal';

// If link is external, return true
export const isExternal = (linkObject) =>
  returnLinkType(linkObject) === 'linkExternal';

export const hasImage = (image) =>
  !!(
    image &&
    image.asset &&
    (image.asset.fluid || image.asset.fixed || image.asset.url)
  );

export const determineColumnWith = (maxNumColumns, numOfItems) => {
  // Coming soon?
  // const numOfItems = (props.data.staffMembers.length);
  //     const maxItemsInRow = 3
  //     const numOfRows = Math.ceil(numOfItems / maxItemsInRow);
  //     const itemsInEachRow = Math.ceil(numOfItems / numOfRows);
  //     const columnWidth =  12 / itemsInEachRow ;
  //     const potentialMax = maxItemsInRow * numOfRows;
  //     const remainder = (itemsInEachRow - (potentialMax - numOfItems));
  //     const lastRowColumnWidth =  12 / (Math.ceil(remainder));
  //     const startLastRow = ((numOfRows - 1) * itemsInEachRow);
  //     const fillLastRow  = false;
};

export const evenOrOdd = (num) => (num % 2 === 1 ? 'odd' : 'even');

export const slugifyModified = (slug, additionalOptions = {}) => {
  const options = {
    replacement: '-', // replace spaces with replacement character, defaults to `-`
    remove: undefined, // remove characters that match regex, defaults to `undefined`
    lower: true, // convert to lower case, defaults to `false`
    strict: true, // strip special characters except replacement, defaults to `false`
  };

  const mergedOptions = _.merge(options, additionalOptions);

  return slugify(slug, mergedOptions);
};

// Use the NPM package 'pluralize' unless you need this one.
export const pluralize = (
  data,
  wordSansEnding,
  singularEnding,
  pluralEnding
) => {
  if (data.length) {
    return `${wordSansEnding}${
      data.length < 2 ? singularEnding : pluralEnding
    }`;
  }
  return `${wordSansEnding}${singularEnding}`;
};

export function toPlainText(blocks = []) {
  return (
    blocks
      // loop through each block
      .map((block) => {
        // if it's not a text block with children,
        // return nothing
        if (block._type !== 'block' || !block.children) {
          return '';
        }
        // loop through the children spans, and join the
        // text strings
        return block.children.map((child) => child.text).join('');
      })
      // join the paragraphs leaving split by two linebreaks
      .join('\n\n')
  );
}

export function nodeToPlainText(block = []) {
  // if it's not a text block with children,
  // return nothing
  if (block._type !== 'block' || !block.children) {
    return '';
  }
  // loop through the children spans, and join the
  // text strings
  return block.children.map((child) => child.text).join('');
}

// export const mergeSeo = (initial, overwrite) => _.merge(initial, overwrite);

export const seoTemplate = {
  title: '',
  url: '',
  type: '',
  keywords: '',
  index: '',
  follow: '',
  image: {
    asset: {
      url: '',
    },
  },
  description: '',
};

export function mergeSeo(seo, overwrites) {
  const seoFinal = seoTemplate;
  seoFinal.title = overwrites.title || seo.title;
  seoFinal.url = overwrites.url || seo.url;
  seoFinal.type = overwrites.type || seo.type;
  seoFinal.keywords = overwrites.keywords || seo.keywords;
  seoFinal.image.asset.url = overwrites?.image?.asset?.url || seo.image;
  seoFinal.follow = overwrites.follow || seo.follow;
  seoFinal.index = overwrites.index || seo.index;
  seoFinal.description = overwrites.description || seo.description;

  return seoFinal;
}

export function truncateString(str, num) {
  // If the length of str is less than or equal to num
  // just return str--don't truncate it.
  if (str.length <= num) {
    return str;
  }
  // Return str truncated with '...' concatenated to the end of str.
  return `${str.slice(0, num)}...`;
}

export function useHasMounted() {
  const [hasMounted, setHasMounted] = React.useState(false);
  React.useEffect(() => {
    setHasMounted(true);
  }, []);
  return hasMounted;
}
