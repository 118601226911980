import React from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Link as GatsbyLink, useStaticQuery, graphql } from 'gatsby';

import { Container, Link, ButtonGroup } from '@elements';
import { colors } from '@theme/index';

const Nav = () => {
  const headerData = useStaticQuery(graphql`
    query navQuery {
      sanitySettings {
        infoBar {
          navigationPages {
            _key
            ...LinkFragment
          }
        }
        logoOnLight {
          asset {
            _id
            _type
            url
            originalFilename
            extension
          }
        }
        logoOnDark {
          asset {
            _id
            _type
            url
            originalFilename
            extension
          }
        }
        navigationPages {
          _key
          ...LinkFragment
        }
        navigationButtons {
          buttons {
            ...ButtonFragment
          }
        }
        siteName
        stickyNav
      }

      locationSettings: sanityLocationSettings {
        applicationLinkManagers
        applicationLinkEmployees
      }
    }
  `);

  const [isNavOpen, toggleNav] = React.useState(false);

  const themeContext = React.useContext(ThemeContext);

  // Add the bottom border on scroll
  React.useEffect(() => {
    // Get the sticky element
    // const navBar = document.querySelector('.navbar');
    // const observer = new IntersectionObserver(
    //   ([e]) => e.target.classList.toggle('shadow-lg', e.intersectionRatio < 1),
    //   { threshold: [1] }
    // );
    // observer.observe(navBar);
  }, []);

  return (
    <NavStyled
      className={`${
        headerData.sanitySettings.stickyNav && 'sticky '
      } z-40 navbar color-mode-dark`}
    >
      <Container noSpace>
        <div className="flex justify-between h-24">
          <div className="flex justify-between items-center w-full">
            <GatsbyLink to="/" className="flex items-center">
              {headerData.sanitySettings &&
                headerData.sanitySettings.logoOnLight &&
                headerData.sanitySettings.logoOnLight &&
                headerData.sanitySettings.logoOnLight.asset &&
                headerData.sanitySettings.logoOnLight.asset.url &&
                themeContext.mode === 'light' && (
                  <img
                    src={headerData.sanitySettings.logoOnLight.asset.url}
                    alt={headerData.sanitySettings.siteName}
                    className="m-0 object-contain w-auto max-h-16"
                  />
                )}

              {headerData.sanitySettings &&
                headerData.sanitySettings.logoOnDark &&
                headerData.sanitySettings.logoOnDark &&
                headerData.sanitySettings.logoOnDark.asset &&
                headerData.sanitySettings.logoOnDark.asset.url &&
                themeContext.mode === 'dark' && (
                  <img
                    src={headerData.sanitySettings.logoOnDark.asset.url}
                    alt={headerData.sanitySettings.siteName}
                    className="m-0 object-contain w-auto max-h-16"
                  />
                )}
            </GatsbyLink>
            <div className="hidden space-x-8 md:-my-px md:ml-6 md:flex text-sm lg:text-base">
              {headerData.sanitySettings.navigationPages.map((item) => (
                <Link
                  key={item._key}
                  link={item}
                  className="inline-flex items-center px-1 pt-1 ml-4 font-semibold text-white no-underline leading-5 border-b-4 border-transparent transition duration-150 ease-in-out lg:ml-8 hover:no-underline hover:text-primary focus:outline-none"
                  activeClassName="text-primary"
                  partiallyActive={!item?.text?.includes('Home')}
                />
              ))}

              {headerData?.sanitySettings?.navigationButtons?.buttons && (
                <ButtonGroup
                  buttons={headerData.sanitySettings.navigationButtons.buttons}
                  hasImageAsBackground
                  removeWrapper
                  buttonClasses="whitespace-no-wrap"
                />
              )}

              {/* <button
                type="button"
                className="snipcart-checkout inline-flex items-center px-1 pt-1 ml-4 font-semibold no-underline leading-5 border-b-4 border-transparent lg:ml-8 hover:no-underline hover:border-primary focus:outline-none focus:border-primary color-mode-color"
              >
                <svg
                  className="-ml-1 mr-2 h-5 w-5 text-primary"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
                </svg>
                Cart
              </button> */}
            </div>
          </div>

          <div className="flex items-center -mr-2 md:hidden ml-6">
            <button
              onClick={() => {
                toggleNav(!isNavOpen);
              }}
              type="button"
              className="inline-flex items-center justify-center p-2 transition duration-150 ease-in-out rounded-md hover:text-secondary focus:outline-none focus:text-secondary"
            >
              <svg
                className="w-6 h-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  className={`inline-flex ${isNavOpen && 'hidden'}`}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
                <path
                  className={`${!isNavOpen && 'hidden'} text-secondary`}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </Container>
      <div
        className={`${
          !isNavOpen ? 'hidden' : 'absolute'
        } md:hidden bg-secondary z-40 w-full`}
      >
        <div className="">
          {headerData?.sanitySettings?.navigationPages.map((item) => (
            <Link
              key={item._key}
              link={item}
              className="block py-4 pl-3 pr-4 text-center text-lg font-semibold text-white no-underline border-b transition duration-150 ease-in-out hover:bg-white hover:text-gray-900 hover:no-underline focus:outline-none focus:bg-white focus:text-secondary focus:no-underline"
            />
          ))}
          {headerData?.sanitySettings?.infoBar?.navigationPages.map((item) => (
            <Link
              key={item._key}
              link={item}
              className="block py-4 pl-3 pr-4 text-center text-lg font-semibold text-white no-underline border-b transition duration-150 ease-in-out hover:bg-white hover:text-gray-900 hover:no-underline focus:outline-none focus:bg-white focus:text-secondary focus:no-underline"
            />
          ))}
          {headerData?.sanitySettings?.navigationButtons?.buttons &&
            headerData.sanitySettings.navigationButtons.buttons.map(
              (button) => (
                <Link
                  key={button._key}
                  link={button.link}
                  className="block py-4 pl-3 pr-4 text-center text-lg font-semibold text-white no-underline border-b transition duration-150 ease-in-out hover:bg-white hover:text-gray-900 hover:no-underline focus:outline-none focus:bg-white focus:text-secondary focus:no-underline"
                />
              )
            )}
        </div>
      </div>
    </NavStyled>
  );
};

export default Nav;

const barHeight = 5;

const NavStyled = styled.nav`
  top: -1px; /* hack to fire observer */
  border-bottom: 6px solid;
  border-bottom-color: ${colors.primary} !important;

  &:after {
    content: '';
    height: ${barHeight}px;
    background-color: ${colors.secondary};
    position: absolute;
    bottom: -${2 * barHeight}px;
    width: 100%;
  }

  &.border-b {
    padding-top: 1px; /* hack to fire observer */
  }
`;

// const NavLink = styled((props) => <Link {...props} />)``;

// const LogoStyled = styled(Logo)`
//   path.textColor {
//     fill: ${textColor};
//   }
// `;

// const LogoSmallStyled = styled(LogoSmall)`
//   path.textColor {
//     fill: ${textColor};
//   }
// `;
