/* eslint no-unused-expressions: 0, react/display-name: 0, array-callback-return: 0 */

import React from 'react';
import BaseBlockContent from '@sanity/block-content-to-react';
import styled from 'styled-components';
import { serializers } from '@utils';

const BlockContent = ({ blocks }) => (
  <BaseBlockContentWrap>
    <BaseBlockContent
      blocks={blocks}
      serializers={serializers}
      convertHeadingsToAnchors={false}
    />
  </BaseBlockContentWrap>
);

const BaseBlockContentWrap = styled.div`
  & > *:last-child {
    margin-bottom: 0 !important;
  }
`;

export default BlockContent;
