import React from 'react';
import Img from 'gatsby-image';
import { getFluidGatsbyImage, getFixedGatsbyImage } from 'gatsby-source-sanity';

import { Link } from '@elements';

const ImageInner = ({
  imageFromRichText,
  image,
  className,
  fluidProps = { maxWidth: 1024 },
  fixedProps,
  hideCaption,
  imageClasses,
  link,
}) => {
  if (image?.asset?._id) {
    let fluid;
    let fixed;
    // Image has an _ID, Then this will work :)
    const sanityConfig = {
      projectId: process.env.GATSBY_SANITY_PROJECT_ID,
      dataset: process.env.GATSBY_SANITY_DATASET,
    };

    if (fixedProps) {
      // Fixed Props are passed, use fixed. Since fluid props are passed by default, 'fluid' is the default state
      fixed = getFixedGatsbyImage(image.asset._id, fixedProps, sanityConfig);
    } else {
      fluid = getFluidGatsbyImage(image.asset._id, fluidProps, sanityConfig);
    }

    return (
      <figure className={`${className} ${imageFromRichText && `my-8`}`}>
        {fluid && (
          <Img
            fluid={fluid}
            alt={image?.alt || image?.caption || "Jewelry"}
            className={`relative mx-auto ${
              image?.roundedCorners && 'rounded-lg'
            } ${image?.shadow && 'shadow-2xl'} ${imageClasses}`}
          />
        )}
        {fixed && (
          <Img
            fixed={fixed}
            alt={image?.alt || image?.caption}
            className={`relative mx-auto ${
              image?.roundedCorners && 'rounded-lg'
            } ${image?.shadow && 'shadow-2xl'}${imageClasses} ${imageClasses}`}
          />
        )}
        {image?.caption && hideCaption !== true && (
          <figcaption className="text-left text-sm pt-2 opacity-75">
            {image.caption}
          </figcaption>
        )}
      </figure>
    );
  }

  return null;
};

export const Image = (props) => {
  if (props?.image?.link?.link && props.image.link.link.length > 0) {
    return (
      <Link link={props.image.link && props.image.link}>
        <ImageInner {...props} />
      </Link>
    );
  }
  return <ImageInner {...props} />;
};
