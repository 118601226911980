import React from 'react';
import { graphql } from 'gatsby';

import BlockContent from '@components/BlockContent';

import { Container } from '@elements';

const ContentNarrow = ({ rawData, textNode, children, className }) => {
  const dataBlock = textNode ? rawData[textNode] : rawData;

  if (children) {
    return (
      <Container className={`lg:max-w-5xl ${className} content`}>
        {children}
      </Container>
    );
  }
  return (
    <Container className={`lg:max-w-5xl ${className} content`}>
      <BlockContent blocks={dataBlock} />
    </Container>
  );
};

export default ContentNarrow;

export const ContentNarrowFragment = graphql`
  fragment ContentNarrowFragment on SanityContentNarrow {
    _key
    _type
    #...GlobalBlockSettingsFragment
    globalBlockSettings {
      ...GlobalBlockSettingsFragment
    }
  }
`;
