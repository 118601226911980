import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import styled from 'styled-components';

const Code = ({
  code,
  language = 'jsx',
  highlightedLines = [],
  style = atomOneDark,
}) => {
  const ADDED = [1, 2];
  const REMOVED = [6];

  if (code) {
    return (
      <SyntaxHighlighterStyled
        className="my-8 rounded-lg shadow-2xl flex p-6 bg-gray-900"
        language={language}
        style={atomOneDark}
        showLineNumbers
        wrapLines
        lineStyle={(lineNumber) => {
          const nesStyle = { display: 'block' };
          if (ADDED.includes(lineNumber)) {
            nesStyle.backgroundColor = '#dbffdb';
          } else if (REMOVED.includes(lineNumber)) {
            nesStyle.backgroundColor = '#ffecec';
          }
          return nesStyle;
        }}
      >
        {code}
      </SyntaxHighlighterStyled>
    );
  }
  return null;
};

export default Code;

const SyntaxHighlighterStyled = styled(SyntaxHighlighter)`
  /* display: flex !important; */
  code {
    font-size: 14px;
    font-family: monospace;
    font-weight: 500;
  }
`;
