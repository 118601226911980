const colors = {
  primary: '#B2D033',
  primaryAlt: '#E0F94B',
  secondary: '#47B1AC',
  secondaryAlt: '#38CAC3',
  tertiary: '#5A3910',
  tertiaryAlt: '#292723',
  white: '#FFFFFF',
  black: '#000000',
  gray1: '#ffffff',
  gray2: '#eceff4',
  gray3: '#e5e9f0',
  gray4: '#d8dee9',
  gray6: '#4c566a',
  gray7: '#635F57',
  gray8: '#46433D',
  // gray9: ‘#2e3440’,
  gray9: '#292723',

  // primary: '#EB5147',
  // secondary: '#F25C05',
  // tertiary: '#F28F16',
  // quaternary: '#F2CA80',

  // From Nord: https://www.nordtheme.com/docs/colors-and-palettes
  // Base component color of "Polar Night".
  //
  // Used for texts, backgrounds, carets and structuring characters like curly- and square brackets.
  nord0: '#2E3440',

  // Lighter shade color of the base component color.
  //
  // Used as a lighter background color for UI elements like status bars.
  nord1: '#3B4252',

  // Lighter shade color of the base component color.
  //
  // Used as line highlighting in the editor.
  // In the UI scope it may be used as selection- and hightlight color.
  nord2: '#434C5E',

  // Lighter shade color of the base component color.
  //
  // Used for comments, invisibles, indent- and wrap guide marker.
  // In the UI scope used as pseudoclass color for disabled elements.
  nord3: '#4C566A',

  // Base component color of "Snow Storm".
  //
  // Main color for text, variables, constants and attributes.
  // In the UI scope used as semi-light background depending on the theme shading design.
  nord4: '#D8DEE9',

  // Lighter shade color of the base component color.
  //
  // Used as a lighter background color for UI elements like status bars.
  // Used as semi-light background depending on the theme shading design.
  nord5: '#E5E9F0',

  // Lighter shade color of the base component color.
  //
  // Used for punctuations, carets and structuring characters like curly- and square brackets.
  nord6: '#ECEFF4',

  // Bluish core color.
  //
  // Used for classes, types and documentation tags.
  nord7: '#8FBCBB',

  // Bluish core accent color.
  //
  // Represents the accent color of the color palette.
  // Main color for primary UI elements and methods/functions.
  nord8: '#88C0D0',

  // Bluish core color.
  //
  // Used for language-specific syntactic/reserved support characters and keywords, operators, tags, units and
  nord9: '#81A1C1',

  // Bluish core color.
  //
  // Used for markup doctypes, import/include/require statements, pre-processor statements and at-rules (`@`).
  nord10: '#5E81AC',

  // Colorful component color.
  //
  // Used for errors, git/diff deletion and linter marker.
  nord11: '#BF616A',

  // Colorful component color.
  //
  // Used for annotations.
  nord12: '#D08770',

  // Colorful component color.
  //
  // Used for escape characters, regular expressions and markup entities.
  // In the UI scope used for warnings and git/diff renamings.
  nord13: '#EBCB8B',

  // Colorful component color.
  //
  // Main color for strings and attribute values.
  // In the UI scope used for git/diff additions and success visualizations.
  nord14: '#A3BE8C',

  // Colorful component color.
  //
  // Used for numbers.
  nord15: '#B48EAD',
};

// export const colors = {
//   light: {
//     primary: baseColors.primary,
//     background: baseColors.gray1,
//     border100: baseColors.gray2,
//     primaryButtonBG: baseColors.primary,
//     secondaryButtonBG: baseColors.secondary,
//     textColor: '#2E3440',
//     buttonColor: '#ECEFF4',
//   },
//   dark: {
//     primary: baseColors.secondary,
//     background: baseColors.gray9,
//     border100: baseColors.gray6,
//     primaryButtonBG: baseColors.primary,
//     secondaryButtonBG: baseColors.secondary,
//     textColor: '#ECEFF4',
//     buttonColor: '#2E3440',
//   },
// };

exports.colors = colors;
