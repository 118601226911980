// These are Module configurations... As of right now there are no rules and Ken Jones is the king of the castle... If you are reading this message, then nothing has changed

module.exports = {
  siteTitle: 'Rock Candy Leatherworks',
  shortname: 'Rock Candy',
  path_prefix: '/',
  backgroundColor: '#FDFDFD',
  themeColor: '#B2D033',
  siteUrl: 'https://www.rockcandymiami.com/',
  siteDescription: 'Rock Candy Leatherworks',
  googleAnalyticsID: 'G-6GE2XKVQQK',
  modules: {
    homepage: {
      activated: true,
    },
    pages: {
      activated: true,
    },
    locations: {
      activated: false,
    },
    regions: {
      activated: false,
    },
    team: {
      activated: true,
    },
    blog: {
      activated: true,
      renderTagPages: true,
      renderCategoryPages: true,
      renderAuthorPages: true,
      postsPerPage: 12,
      baseName: 'blog',
    },
  },
};
