import React from 'react';
import ReactPlayer from 'react-player';
import InstagramEmbed from 'react-instagram-embed';
import styled from 'styled-components';

import {
  YoutubePlayer,
  Link,
  Image,
  ButtonGroup,
  FacebookIframe,
} from '@elements';
import ListWithIcons from '@components/ListWithIcons';
import Code from '@components/Code';

export const serializers = {
  types: {
    block(props) {
      // console.log('props.children:', props.node);
      switch (props.node.style) {
        case 'h1':
          return <h1>{props.children}</h1>;

        case 'h2':
          return <h2>{props.children}</h2>;

        case 'h3':
          return <h3>{props.children}</h3>;

        case 'h4':
          return <h4>{props.children}</h4>;

        case 'h5':
          return <h5>{props.children}</h5>;

        case 'h6':
          return <h6>{props.children}</h6>;

        case 'subHeading':
          return <div className="subHeading">{props.children}</div>;

        case 'sm':
          return <p className={`text-sm `}>{props.children}</p>;

        case 'lg':
          return (
            <p className="text-xl leading-7 sm:text-2xl sm:leading-8">
              {props.children}
            </p>
          );

        case 'xl':
          return (
            <p className={`text-2xl leading-8 sm:text-3xl sm:leading-9 `}>
              {props.children}
            </p>
          );

        default:
          return <p className="">{props.children}</p>;
      }
    },
    youtube: ({ node }) => {
      const opts = {
        height: '100%',
        width: '100%',
        color: 'white',
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 0,
        },
      };
      const { url } = node;
      const options = {
        roundedCorners:
          node && node.roundedCorners ? node.roundedCorners : false,
        shadow: node && node.shadow ? node.shadow : false,
      };
      return <YoutubePlayer opts={opts} url={url} options={options} />;
    },
    // Renders custom list element from a custom Sanity List element
    list: ({ node }) => <ListWithIcons items={node.listItems} inRichText />,
    gallerySlider: ({ node }) => <div />,
    collectionBoxes: ({ node }) => <div />,
    figureWithOptions: ({ node }) => <Image image={node} imageFromRichText />,
    // Renders buttons from a custom Sanity array of Buttons
    buttonGroup: ({ node }) => (
      <ButtonGroup orientation={node.orientation} buttons={node.buttons} />
    ),
    videoEmbed: ({ node }) => (
      <ReactPlayer className="mt-6 mb-6" url={node.url} controls />
    ),
    code: ({ node }) => (
      <Code
        code={node.code}
        language={node.language}
        highlightedLines={node.highlightedLines}
      />
    ),
    instagram: ({ node }) => {
      if (!node.url) return null;
      return (
        <InstagramEmbedStyle
          url={node.url}
          className="flex justify-center my-8"
        />
      );
    },
    facebookIframe: ({ node }) => {
      if (!node.url) return null;
      return (
        <FacebookIframe url={node.url} className="flex justify-center my-8" />
      );
    },
  },
  marks: {
    center: (props) => (
      <span className="text-center block">{props.children}</span>
    ),
    textPrimary: ({ children }) => (
      <span className="text-primary">{children}</span>
    ),
    link: ({ mark, children }) => (
      <Link link={mark.link} text={children[0]}>
        {children}
      </Link>
    ),
  },
};

const InstagramEmbedStyle = styled(InstagramEmbed)`
  & > iframe {
    width: 100% !important;
    max-width: none !important;
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }
`;
