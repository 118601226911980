import React from 'react';

import { Button } from '@elements';

export const ButtonGroup = ({
  buttons,
  backgroundColor,
  hasImageAsBackground,
  className,
  orientation,
  removeWrapper,
  buttonClasses,
}) => {
  let align = 'justify-center';

  if (orientation) {
    if (orientation === 'left') {
      align = 'justify-start';
    }
    if (orientation === 'right') {
      align = 'justify-end';
    }
  }

  if (buttons && buttons.length) {
    if (removeWrapper) {
      return (
        <Buttons
          buttons={buttons}
          backgroundColor={backgroundColor}
          hasImageAsBackground={hasImageAsBackground}
          passedButtonClasses={buttonClasses}
        />
      );
    }
    return (
      <div
        className={`mt-8 flex flex-col md:flex-row mb-6 items-center ${align} ${className}`}
      >
        <Buttons
          buttons={buttons}
          backgroundColor={backgroundColor}
          hasImageAsBackground={hasImageAsBackground}
          passedButtonClasses={buttonClasses}
        />
      </div>
    );
  }

  return null;
};

const Buttons = ({
  buttons,
  backgroundColor,
  hasImageAsBackground,
  passedButtonClasses,
}) => (
  <>
    {buttons &&
      buttons.length &&
      buttons.map((button, i) => {
        let buttonClasses;
        let buttonGhostClasses;

        if (hasImageAsBackground) {
          buttonClasses = ``;
          buttonGhostClasses = ``;
        } else if (
          backgroundColor === 'primary' ||
          backgroundColor === 'secondary'
        ) {
          buttonClasses = `hover:bg-tertiary hover:border-tertiary text-white`;
          buttonGhostClasses = `hover:border-white hover:bg-white hover:text-gray-900`;
        } else {
          buttonClasses = ``;
          buttonGhostClasses = ``;
        }

        return (
          <Button
            key={button._key}
            buttonObject={button}
            className={`w-3/4 md:w-auto ${i > 0 && 'mt-3 md:ml-3 md:mt-0'} ${
              button.isGhost === true ? buttonGhostClasses : buttonClasses
            } ${passedButtonClasses}`}
          />
        );
      })}
  </>
);
