import React from 'react';
import SVG from 'react-inlinesvg';

export const IconBox = ({ color, iconImage, className }) => (
  <>
    {iconImage.asset && iconImage.asset.url && (
      <div
        className={`${className} flex items-center justify-center h-12 w-12 rounded-md ${
          color && (color === 'primary' || color === 'secondary')
            ? `${color === 'primary' && 'bg-primary'} ${
                color === 'secondary' && 'bg-secondary'
              } svgLight`
            : 'color-mode-background-opposite color-mode-color-opposite hasSVG'
        }`}
      >
        <SVG className="" src={iconImage.asset.url} />
      </div>
    )}
  </>
);
