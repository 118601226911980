import React from 'react';
import { Link as GatsbyLink, graphql } from 'gatsby';
import styled from 'styled-components';

import { linkResolver } from '@utils/helpers';

export const Link = ({
  link,
  className,
  activeClassName,
  partiallyActive,
  children,
}) => {
  const linkData = linkResolver(link);

  if (linkData && linkData.type === 'internal') {
    // is internal link
    if (linkData.slugType === 'product') {
      return (
        <LinkStyled
          as="button"
          title={linkData.title}
          className={`snipcart-add-item ${className} ${
            link.removeUnderline && 'no-underline'
          }`}
          data-item-id={linkData.productInfo.sku}
          data-item-price={linkData.productInfo.price}
          data-item-url={`/${linkData.productInfo.slug}`}
          data-item-description={linkData.productInfo.description}
          data-item-image={linkData.productInfo.image}
          data-item-name={linkData.productInfo.name}
        >
          {children || linkData.text}
        </LinkStyled>
      );
    }

    return (
      <LinkStyled
        as={GatsbyLink}
        to={`/${linkData.href}${linkData.anchor}`}
        title={linkData.title}
        className={`${className} ${link.removeUnderline && 'no-underline'}`}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
      >
        {children || linkData.text}
      </LinkStyled>
    );
  }

  // if (linkData && linkData.type === 'internal') {
  //   console.log('linkData:', linkData);
  //   // is internal link
  //   return (
  //     <LinkStyled
  //       as={GatsbyLink}
  //       to={`${linkData.href}/${linkData.anchor}`}
  //       title={linkData.title}
  //       className={`${className} ${link.removeUnderline && 'no-underline'}`}
  //       activeClassName={activeClassName}
  //       partiallyActive={partiallyActive}
  //     >
  //       {children || linkData.text}
  //     </LinkStyled>
  //   );
  // }

  if (linkData && linkData.type === 'external') {
    // is external link
    return (
      <LinkStyled
        href={`${linkData.href}${linkData.anchor && `${linkData.anchor}`}`}
        title={linkData.title}
        rel={linkData.openNew && 'noopener noreferrer'}
        target={linkData.openNew && '_blank'}
        className={`${className} ${link.removeUnderline && 'no-underline'}`}
      >
        {children || linkData.text}
      </LinkStyled>
    );
  }

  if (linkData === null && link && link.anchor && link.anchor.current) {
    return (
      <LinkStyled
        href={`/${link.anchor.current}`}
        title={link.title && link.title}
        className={`${className} ${link.removeUnderline && 'no-underline'}`}
      >
        {children[0] || linkData.text}
      </LinkStyled>
    );
  }

  return null;
};

const LinkStyled = styled.a``;

export const LinkFragment = graphql`
  fragment LinkFragment on SanityLink {
    _type
    anchor {
      current
    }
    removeUnderline
    text
    title
    link {
      ... on SanityLinkExternal {
        _key
        _type
        url
        openNew
      }
      ... on SanityLinkInternal {
        ...InternalLinkFragment
      }
    }
  }
`;

export const LinkForRichTextFragment = graphql`
  fragment LinkForRichTextFragment on SanityLinkForRichText {
    _type
    anchor {
      current
    }
    removeUnderline
    title
    link {
      ... on SanityLinkExternal {
        _key
        _type
        url
        openNew
      }
      ... on SanityLinkInternal {
        ...InternalLinkFragment
      }
    }
  }
`;

export const InternalLinkFragment = graphql`
  fragment InternalLinkFragment on SanityLinkInternal {
    _key
    _type
    slug {
      ... on SanityHomepage {
        pageInfo {
          name
          slug {
            current
          }
        }
        id
        _type
      }
      ... on SanityPage {
        pageInfo {
          name
          slug {
            current
          }
        }
        id
        _type
      }
      ... on SanityProduct {
        pageInfo {
          name
          slug {
            current
          }
        }
        id
        _type
        price
        sku
        descriptionCart
        image {
          asset {
            _id
            url
          }
        }
      }
      ... on SanityLocation {
        _type
        id
        pageInfo {
          name
          slug {
            current
          }
        }
        group {
          pageInfo {
            name
            slug {
              current
            }
          }
        }
      }
      ... on SanityLocationPage {
        _type
        id
        pageInfo {
          name
          slug {
            current
          }
        }
      }
      ... on SanityBlogSettings {
        _type
        id
        pageInfo {
          name
          slug {
            current
          }
        }
      }
      ... on SanityPost {
        _type
        id
        pageInfo {
          name
          slug {
            current
          }
        }
      }
      ... on SanityTeamSettings {
        _type
        id
        pageInfo {
          name
          slug {
            current
          }
        }
      }
      # ... on SanityTeam {
      #   _type
      #   id
      #   pageInfo {
      #     name
      #     slug {
      #       current
      #     }
      #   }
      # }
    }
  }
`;
