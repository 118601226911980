import React from 'react';
import { ThemeContext } from 'styled-components';
import { useStaticQuery, graphql, Link as GatsbyLink } from 'gatsby';

import { Link } from '@elements';

const Footer = () => {
  const footerData = useStaticQuery(graphql`
    query footerQuery {
      sanitySettings {
        siteName
        copyright
        footerNavigationHeader
        footerNavigationPages {
          _key
          ...LinkFragment
        }
        logoOnLight {
          asset {
            _id
            _type
            url
            originalFilename
            extension
          }
        }
        logoOnDark {
          asset {
            _id
            _type
            url
            originalFilename
            extension
          }
        }
        socialMediaHandles {
          facebook
          instagram
          linkedIn
          twitter
        }
      }

      allSanityLocationGroupNew {
        edges {
          node {
            id
            pageInfo {
              name
              slug {
                current
              }
            }
          }
        }
      }

      sanityLocationPage {
        pageInfo {
          name
          slug {
            current
          }
        }
      }
    }
  `);

  const themeContext = React.useContext(ThemeContext);

  return (
    <footer className="color-mode-dark border-t pt-20 pb-3">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 md:px-8">
        <div className="md:grid md:grid-cols-2 md:gap-8">
          <div className="flex flex-col items-center sm:block lg:col-span-1">
            <GatsbyLink
              to="/"
              className="flex flex-col items-start justify-center"
            >
              {themeContext.mode === 'light' && (
                <>
                  {footerData.sanitySettings &&
                  footerData.sanitySettings.logoOnLight &&
                  footerData.sanitySettings.logoOnLight &&
                  footerData.sanitySettings.logoOnLight.asset &&
                  footerData.sanitySettings.logoOnLight.asset.url ? (
                    <img
                      src={footerData.sanitySettings.logoOnLight.asset.url}
                      alt={footerData.sanitySettings.siteName}
                      className="m-0 object-contain w-auto max-h-10"
                    />
                  ) : (
                    <>{footerData.sanitySettings.siteName}</>
                  )}
                </>
              )}

              {themeContext.mode === 'dark' && (
                <>
                  {footerData.sanitySettings &&
                  footerData.sanitySettings.logoOnDark &&
                  footerData.sanitySettings.logoOnDark &&
                  footerData.sanitySettings.logoOnDark.asset &&
                  footerData.sanitySettings.logoOnDark.asset.url ? (
                    <img
                      src={footerData.sanitySettings.logoOnDark.asset.url}
                      alt={footerData.sanitySettings.siteName}
                      className="m-0 object-contain w-auto max-h-10"
                    />
                  ) : (
                    <>{footerData.sanitySettings.siteName}</>
                  )}
                </>
              )}
            </GatsbyLink>
            <div className="mt-8 flex space-x-4">
              {footerData &&
                footerData.sanitySettings &&
                footerData.sanitySettings.socialMediaHandles &&
                footerData.sanitySettings.socialMediaHandles.facebook && (
                  <a
                    className="text-inherit hover:text-primary"
                    href={`https://facebook.com/${footerData.sanitySettings.socialMediaHandles.facebook}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg
                      className="w-4 h-4 fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.299 2.49h1.435V.117A19.136 19.136 0 009.654 0C7.633 0 6.227 1.26 6.227 3.545v2.08H4v2.666h2.227V15H8.98V8.291h2.168l.352-2.666H8.98V3.809c0-.791.235-1.319 1.319-1.319z" />
                    </svg>
                  </a>
                )}
              {footerData &&
                footerData.sanitySettings &&
                footerData.sanitySettings.socialMediaHandles &&
                footerData.sanitySettings.socialMediaHandles.instagram && (
                  <a
                    className="text-inherit hover:text-primary"
                    href={`https://instagram.com/${footerData.sanitySettings.socialMediaHandles.instagram}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg
                      className="w-4 h-4 fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M7.577 4.179a3.37 3.37 0 00-3.369 3.369 3.351 3.351 0 003.37 3.369 3.37 3.37 0 003.368-3.37A3.389 3.389 0 007.577 4.18zm0 5.566A2.194 2.194 0 015.38 7.548c0-1.201.967-2.168 2.197-2.168 1.201 0 2.168.967 2.168 2.168 0 1.23-.967 2.197-2.168 2.197zm4.277-5.683a.788.788 0 00-.79-.791.788.788 0 00-.791.79c0 .44.351.792.79.792.44 0 .791-.352.791-.791zm2.227.79c-.058-1.054-.293-1.992-1.055-2.753-.761-.762-1.699-.996-2.753-1.055-1.085-.059-4.336-.059-5.42 0-1.055.059-1.963.293-2.754 1.055-.762.761-.996 1.699-1.055 2.754-.059 1.084-.059 4.335 0 5.42.059 1.054.293 1.962 1.055 2.753.79.762 1.699.997 2.754 1.055 1.084.059 4.335.059 5.42 0 1.054-.058 1.992-.293 2.753-1.055.762-.79.997-1.699 1.055-2.753.059-1.085.059-4.336 0-5.42zm-1.406 6.563c-.205.586-.674 1.025-1.23 1.26-.88.351-2.93.263-3.868.263-.967 0-3.017.088-3.867-.263a2.221 2.221 0 01-1.26-1.26c-.351-.85-.263-2.9-.263-3.867 0-.938-.088-2.988.263-3.867a2.26 2.26 0 011.26-1.23c.85-.352 2.9-.264 3.867-.264.938 0 2.988-.088 3.867.263.557.205.996.674 1.23 1.23.352.88.264 2.93.264 3.868 0 .967.088 3.017-.263 3.867z" />
                    </svg>
                  </a>
                )}
              {footerData &&
                footerData.sanitySettings &&
                footerData.sanitySettings.socialMediaHandles &&
                footerData.sanitySettings.socialMediaHandles.twitter && (
                  <a
                    className="text-inherit hover:text-primary"
                    href={`https://twitter.com/${footerData.sanitySettings.socialMediaHandles.twitter}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg
                      className="w-4 h-4 fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M5.032 13C11.0696 13 14.372 7.99865 14.372 3.66195C14.372 3.51958 14.372 3.37801 14.3624 3.23804C15.0049 2.77296 15.5595 2.19715 16 1.5376C15.4009 1.80314 14.7652 1.97731 14.1144 2.05429C14.7997 1.64402 15.3126 0.998789 15.5576 0.238669C14.9134 0.620898 14.2086 0.890316 13.4736 1.0353C12.9787 0.508824 12.324 0.160153 11.6109 0.0432734C10.8978 -0.0736058 10.166 0.047828 9.5289 0.388772C8.89178 0.729717 8.38486 1.27115 8.08663 1.92924C7.7884 2.58734 7.71549 3.32537 7.8792 4.02908C6.57407 3.96368 5.29728 3.62462 4.13172 3.0339C2.96615 2.44318 1.93786 1.614 1.1136 0.600193C0.693854 1.3226 0.565271 2.1778 0.754029 2.99166C0.942786 3.80553 1.43469 4.51688 2.1296 4.98088C1.60741 4.96576 1.09651 4.82531 0.64 4.57136V4.61295C0.640311 5.37063 0.902716 6.10489 1.38272 6.69121C1.86272 7.27753 2.53077 7.67982 3.2736 7.82988C2.79025 7.96163 2.28311 7.98078 1.7912 7.88587C2.00093 8.53811 2.40934 9.10849 2.9593 9.51722C3.50926 9.92595 4.17326 10.1526 4.8584 10.1654C4.1777 10.7001 3.39823 11.0955 2.56458 11.3288C1.73093 11.5621 0.859458 11.6288 0 11.5251C1.50128 12.4884 3.24815 12.9993 5.032 12.9968" />
                    </svg>
                  </a>
                )}
              {footerData &&
                footerData.sanitySettings &&
                footerData.sanitySettings.socialMediaHandles &&
                footerData.sanitySettings.socialMediaHandles.linkedIn && (
                  <a
                    className="text-inherit hover:text-primary"
                    href={`https://linkedin.com/${footerData.sanitySettings.socialMediaHandles.linkedIn}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg
                      className="w-4 h-4 fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M3.93 14.096v-8.76H1.205v8.76H3.93zM2.553 4.164c.879 0 1.582-.732 1.582-1.611C4.135 1.703 3.432 1 2.553 1 1.703 1 1 1.703 1 2.553c0 .879.703 1.611 1.553 1.611zm11.572 9.932V9.29c0-2.344-.527-4.16-3.281-4.16-1.319 0-2.198.732-2.578 1.406h-.03V5.336H5.63v8.76h2.725V9.76c0-1.143.205-2.227 1.61-2.227 1.407 0 1.436 1.29 1.436 2.315v4.248h2.725z" />
                    </svg>
                  </a>
                )}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 mt-10 sm:grid-cols-2 lg:gap-8 md:mt-0">
            <nav>
              {footerData &&
                footerData.sanitySettings &&
                footerData.sanitySettings.footerNavigationHeader && (
                  <h4 className="text-sm leading-5 tracking-wider uppercase">
                    {footerData.sanitySettings.footerNavigationHeader}
                  </h4>
                )}
              <ul className="mt-6">
                {footerData.sanitySettings.footerNavigationPages.map(
                  (item, i) => (
                    <li key={item._key}>
                      <Link
                        link={item}
                        className="text-base leading-6 text-inherit no-underline"
                      />
                    </li>
                  )
                )}
                {/* <li className="mt-2">
                  <a
                    href={footerData.sanitySettings.applyUrl}
                    className="text-primary font-bold underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Apply Now
                  </a>
                </li> */}
              </ul>
            </nav>
            {/* <div className="">
              <h4 className="text-sm leading-5 tracking-wider uppercase">
                Legal
              </h4>
              <ul className="mt-6">
                <li className="mt-2">
                  <a
                    href="/"
                    className="text-base leading-6 text-inherit no-underline"
                  >
                    Terms &amp;
                    <br />
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </div>
      {footerData &&
        footerData.sanitySettings &&
        footerData.sanitySettings.copyright && (
          <div className="mt-12 border-t pt-10">
            <p className="text-xs leading-6 text-center mb-1">
              {footerData.sanitySettings.copyright.replace(
                /%year%/gi,
                new Date().getFullYear()
              )}
            </p>
            <p className="text-xs leading-6 text-center">
              Website design &amp; development by{' '}
              <a
                href="https://allday.io"
                target="_blank"
                rel="noopener noreferrer"
                className="font-bold text-primary"
              >
                AllDay.io
              </a>
              .
            </p>
          </div>
        )}
    </footer>
  );
};

export default Footer;

export const SocialMediaHandlesFragment = graphql`
  fragment SocialMediaHandlesFragment on SanitySocialMediaHandles {
    facebook
    instagram
    linkedIn
    twitter
    pinterest
    youtube
  }
`;
