import React from 'react';

export const FacebookIframe = ({ url, className }) => (
  <div className={`mt-0 bg-white border max-w-xl p-4 ${className}`}>
    <iframe
      title="facebook embed"
      src={`https://www.facebook.com/plugins/post.php?href=${encodeURI(
        url
      )}&show_text=true&width=552&appId=264432381251684`}
      height="610"
      style={{ maxHeight: '100vh' }}
      className="w-full"
      scrolling="no"
      frameBorder="0"
      allowTransparency="true"
      allow="encrypted-media"
    />
  </div>
);
